import {
  UseBaseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { graphql } from 'src/gql';
import type {
  GetPropertyComplexesQueryVariables,
  GetPropertiesQueryVariables,
  GetPropertyQueryVariables,
  UpdatePropertyMutationVariables,
  GetPropertyAnnualRentalIncomePredictQueryVariables,
  UpdatePicturesPropertyMutationVariables,
  UpdateStatusPropertyMutationVariables,
  CreatePropertyMutationVariables,
  UpsertPropertyMutationVariables,
} from 'src/gql/graphql';
import { isArray, isEmpty, map } from 'lodash';
import { graphqlRequest, queryClient } from './_request';
import { s3Upload } from './s3';

const getPropertiesDocument = graphql(/* GraphQL */ `
  query GetProperties(
    $ids: [ID!]
    $status: [PropertyStatus!]
    $limit: Int
    $offset: Int
    $search: String
    $cityId: ID
    $areaId: ID
    $subType: [String!]
    $buildType: String
    $complexId: ID
    $minBedrooms: String
    $maxBedrooms: String
    $minPrice: String
    $maxPrice: String
    $minProfit: String
    $maxProfit: String
  ) {
    items: getProperties(
      ids: $ids
      status: $status
      limit: $limit
      offset: $offset
      search: $search
      cityId: $cityId
      areaId: $areaId
      subType: $subType
      buildType: $buildType
      complexId: $complexId
      minBedrooms: $minBedrooms
      maxBedrooms: $maxBedrooms
      minPrice: $minPrice
      maxPrice: $maxPrice
      minProfit: $minProfit
      maxProfit: $maxProfit
    ) {
      id
      name
      cover
      resaleRef
      listNumber
      bedrooms
      price
      offerType
      subType {
        name
      }
      buildType {
        key
        name
      }
      status {
        key
        name
      }
      avgAnnualRentalIncome
      profit
      profitWithInflation
      parent {
        id
        name
        resaleRef
      }
      location {
        city {
          name
        }
      }
      updatedAt
      updatedBy {
        name
        email
      }
      createdAt
      createdBy {
        name
        email
      }
    }
    statuses: getPropertyStatusCounts(
      search: $search
      cityId: $cityId
      areaId: $areaId
      subType: $subType
      buildType: $buildType
      complexId: $complexId
      minBedrooms: $minBedrooms
      maxBedrooms: $maxBedrooms
      minPrice: $minPrice
      maxPrice: $maxPrice
      minProfit: $minProfit
      maxProfit: $maxProfit
    ) {
      key
      name
      count
    }
  }
`);

const getPropertiesComplexesDocument = graphql(/* GraphQL */ `
  query getPropertyComplexes($search: String, $status: [PropertyStatus!]) {
    items: getProperties(
      search: $search
      status: $status
      subType: "SubType_Complex"
    ) {
      id
      name
      resaleRef
      city {
        name
      }
      status {
        key
        name
      }
      cover
      createdAt
    }
  }
`);

const getPropertyDocument = graphql(/* GraphQL */ `
  query GetProperty($id: ID!) {
    property: getProperty(id: $id) {
      id
      listNumber
      status {
        key
        name
      }
      type {
        key
        name
      }
      subType {
        key
        name
        icon
      }
      buildType {
        key
        name
        icon
      }
      name
      description
      content
      cover
      pictures
      price
      bedrooms
      bathrooms
      agentCommission
      terraceSize
      livingSize
      builtSize
      gardenPlotSize
      guests
      floor
      levels
      estimates {
        annualDailyRates
        annualOccupancyRates
      }
      completionDateAt
      completionType {
        key
        name
        icon
      }
      condition {
        key
        name
        icon
      }
      climateControl {
        key
        name
        icon
      }
      features {
        key
        name
        icon
      }
      kitchen {
        key
        name
        icon
      }
      locationType {
        key
        name
        icon
      }
      parking {
        key
        name
        icon
      }
      pool {
        key
        name
        icon
      }
      security {
        key
        name
        icon
      }
      view {
        key
        name
        icon
      }
      furniture {
        key
        name
        icon
      }
      communityFees
      garbageTax
      ibiFees
      offerType
      avgAnnualRentalIncome
      profit
      resaleRef
      countryId
      provinceId
      cityId
      areaId
      street
      houseNumber
      postalCode
      location {
        address
        latitude
        longitude
        isSpecificCoordinates
        googleMapsUrl
      }
      latitude
      longitude
      parent {
        id
        name
        resaleRef
        type {
          key
          name
        }
      }
      createdAt
      updatedAt
      createdBy {
        name
        email
      }
      updatedBy {
        name
        email
      }
    }
  }
`);

const getPropertiesStatusesDocument = graphql(/* GraphQL */ `
  query GetPropertyStatuses {
    items: getPropertyStatuses {
      key
      name
    }
  }
`);

const getPropertyAnnualRentalIncomePredictDocument = graphql(/* GraphQL */ `
  query GetPropertyAnnualRentalIncomePredict(
    $cityId: ID
    $areaId: ID
    $bedrooms: Int
    $bathrooms: Int
    $guests: Int
    $terraceSize: Int
    $subType: String
    $features: [String!]
    $climateControl: [String!]
    $view: [String!]
  ) {
    items: getPropertyAnnualRentalIncomePredict(
      cityId: $cityId
      areaId: $areaId
      bedrooms: $bedrooms
      bathrooms: $bathrooms
      guests: $guests
      terraceSize: $terraceSize
      subType: $subType
      features: $features
      climateControl: $climateControl
      view: $view
    ) {
      type
      format
      value
      month
      year
      date
    }
  }
`);

const upsertPropertyDocument = graphql(/* GraphQL */ `
  mutation UpsertProperty($data: UpsertPropertyInput!, $id: ID) {
    propertyId: upsertProperty(data: $data, id: $id)
  }
`);

const updatePropertyDocument = graphql(/* GraphQL */ `
  mutation UpdateProperty($id: ID!, $data: UpdatePropertyInput!) {
    propertyId: updateProperty(id: $id, data: $data)
  }
`);

const updatePicturesPropertyDocument = graphql(/* GraphQL */ `
  mutation UpdatePicturesProperty(
    $id: ID!
    $data: UpdatePicturesPropertyInput!
  ) {
    propertyId: updatePicturesProperty(id: $id, data: $data)
  }
`);

const updateStatusPropertyDocument = graphql(/* GraphQL */ `
  mutation UpdateStatusProperty($id: ID!, $status: PropertyStatus!) {
    propertyId: updateStatusProperty(id: $id, status: $status)
  }
`);

const createPropertyDocument = graphql(/* GraphQL */ `
  mutation CreateProperty($data: CreatePropertyInput!) {
    propertyId: createProperty(data: $data)
  }
`);

export function getProperties(args: GetPropertiesQueryVariables = {}) {
  return graphqlRequest({
    document: getPropertiesDocument,
    variables: args,
  });
}

export function useGetPropertiesQuery(args: GetPropertiesQueryVariables = {}) {
  return useQuery({
    queryKey: ['properties', 'list', args],
    staleTime: Infinity,
    queryFn: () => getProperties(args),
  });
}

export function useGetPropertyComplexesQuery(
  args: GetPropertyComplexesQueryVariables = {}
) {
  return useQuery({
    queryKey: ['properties', 'complexes', args],
    staleTime: Infinity,
    queryFn: async () =>
      graphqlRequest({
        document: getPropertiesComplexesDocument,
        variables: args,
      }),
  });
}

export function useGetPropertyQuery(
  { id }: GetPropertyQueryVariables,
  options: Pick<UseBaseQueryOptions, 'enabled'> = {}
) {
  return useQuery({
    ...options,
    queryKey: ['properties', 'overview', id],
    staleTime: Infinity,
    queryFn: async () =>
      graphqlRequest({
        document: getPropertyDocument,
        variables: { id },
      }),
  });
}

export function useGetPropertyStatusesQuery() {
  return useQuery({
    queryKey: ['properties', 'statuses'],
    staleTime: Infinity,
    queryFn: async () =>
      graphqlRequest({
        document: getPropertiesStatusesDocument,
      }),
  });
}

export function useGetPropertyAnnualRentalIncomePredictQuery(
  variables: GetPropertyAnnualRentalIncomePredictQueryVariables
) {
  return useQuery({
    queryKey: ['predict', variables],
    staleTime: Infinity,
    queryFn: () =>
      graphqlRequest({
        document: getPropertyAnnualRentalIncomePredictDocument,
        variables,
      }),
  });
}

/** @deprecated */
export function useUpdatePropertyMutation() {
  return useMutation({
    mutationFn: async (variables: UpdatePropertyMutationVariables) =>
      graphqlRequest({
        document: updatePropertyDocument,
        variables,
      }),
  });
}

export function useUpsertPropertyMutation() {
  return useMutation({
    mutationFn: async (variables: UpsertPropertyMutationVariables) =>
      graphqlRequest({
        document: upsertPropertyDocument,
        variables,
      }),
  });
}

export function useUpdatePicturesPropertyMutation() {
  return useMutation({
    mutationFn: async ({
      id,
      blobs,
      data,
    }: UpdatePicturesPropertyMutationVariables & {
      blobs?: Record<string, File | Blob>;
    }) => {
      // Replace blobs with S3 urls if exists
      if (!isEmpty(data.pictures) && !isEmpty(blobs)) {
        await Promise.all(
          map(blobs, async (file, url) => {
            // Upload to S3
            const uploaded = await s3Upload({
              key: `properties/${id}/${file.name}`,
              file,
            });

            // Replace blob with S3 url
            data.pictures = (isArray(data.pictures) ? data.pictures : []).map(
              (p) => (p === url ? uploaded.url : p)
            );
          })
        );
      }

      // Clear blobPictures
      if (!isEmpty(blobs)) {
        Object.keys(blobs).forEach((key) => URL.revokeObjectURL(key));
      }

      // Update pictures (with S3 urls if exists)
      return graphqlRequest({
        document: updatePicturesPropertyDocument,
        variables: { id, data },
      });
    },
  });
}

export function useUpdateStatusPropertyMutation() {
  return useMutation({
    mutationFn: async (variables: UpdateStatusPropertyMutationVariables) =>
      graphqlRequest({
        document: updateStatusPropertyDocument,
        variables,
      }),
  });
}

/** @deprecated */
export function useCreatePropertyMutation() {
  return useMutation({
    mutationFn: async (variables: CreatePropertyMutationVariables) =>
      graphqlRequest({
        document: createPropertyDocument,
        variables,
      }),
  });
}

export function invalidateGetPropertyQueries({
  id,
}: GetPropertyQueryVariables) {
  return queryClient.invalidateQueries({
    queryKey: ['properties', 'overview', id],
  });
}

export function invalidateGetPropertiesQueries() {
  return queryClient.invalidateQueries({
    queryKey: ['properties', 'list'],
  });
}
