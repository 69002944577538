import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DeepPartial } from 'react-hook-form';
import FileThumbnail from 'src/components/file-thumbnail';
import Iconify from 'src/components/iconify';
import { PropertyModel } from 'src/gql/graphql';
import { BASE_URL } from 'src/services/_request';

type Props = {
  property: DeepPartial<PropertyModel>;
};

export default function PropertyAssetsView({ property }: Props) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <Stack direction="row" alignItems="center" gap={2}>
                <FileThumbnail file="pdf" />
                <Typography variant="inherit">Reviero Branding</Typography>
              </Stack>
            </TableCell>
            <TableCell align="right">
              <Button
                href={`${BASE_URL}/property-reports/${property.id}/lifestyle`}
                variant="outlined"
                target="_blank"
                startIcon={<Iconify icon="mingcute:download-2-line" />}
              >
                Download
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
